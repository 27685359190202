<template>
  <div class="form">
    <div class="content">
      <Backlink title="documents" :service="currentServiceStr" />
      <div class="content__container content__container_profile">
        <div v-if="services.length > 0">
          <div v-if="services.length > 1">
            <v-select
              class="service__select"
              :items="services"
              :label="'documents_service' | localize"
              item-text="name"
              item-value="id"
              outlined
              v-model="currentUserService"
              @change="changedService(services.find((item) => item.id === currentUserService))"
            ></v-select>
          </div>
          <div v-if="serviceData">
            <item-service :service="serviceData" :docs="true" />
          </div>
          <div class="desktop" v-show="!currentUserService">
            {{'documents_choose_services' | localize}}
          </div>
          <div v-if="currentUserService">
            <component
              v-for="(client_document, idx) in client_documents"
              :key="idx"
              :is="renderComponent"
              :service_id="currentService"
              :user_service_id="currentUserService"
              :user="client_document.client || {}"
              :documents="client_document.documents || []"
              sortKey="all"
              :filterChange="filterChange"
              :filterChanged = "filterChanged"
            ></component>
          </div>
        </div>
        <div class="error__container">
          <p class="error" v-if="error">{{ error | localize }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink'
import ItemService from '@/components/profile/ItemService'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Documents',
  components: { ItemService, Backlink },
  mixins: [commonMixin],
  props: { service: { type: String, default: null } },
  data: () => ({
    filterChange: false,
    client_documents: [],
    services: [],
    currentServiceStr: null,
    currentUserService: null,
    error: null
  }),
  created () {
    this.loadServices()
  },
  computed: {
    renderComponent () {
      return () => import('@/components/profile/MyDocuments')
    }
  },
  watch: { 
    currentService () {
      this.changedService(this.serviceData)
    }
  },
  methods: {
    changedService (service) {
      this.currentUserService = service.id
      this.currentServiceStr = this.getServiceStr(service.service_id)
      this.filterChange = true

      const requestParams = {
        params: {
          user_id: this.$store.state.user.id || 0,
          user_service_id: service.id
        }
      }
      this.$store.commit('loading', true)
      this.axios
        .get('client_documents', requestParams)
        .then((response) => {
          this.client_documents = response.data
          this.$store.commit('loading', false)
        })
        .catch(() => {
          this.error = 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    },
    filterChanged () {
      this.filterChange = false
    }
  }
}
</script>
